import { Container } from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import GalleryOptionsSpeedDial from './GalleryOptionsSpeedDial';
import Gallery from './Gallery/Gallery';

export function Portfolio() {

    // load commercial gallery urls
    var nums = Array.from(Array(11).keys())
    const gal1 = []
    nums.forEach(num => {
        gal1.push(require(`../images/gallery/commercial/commercial${num}.webp`))
    });

    // load residential gallery urls
    nums = Array.from(Array(123).keys())
    const gal2 = []
    nums.forEach(num => {
        gal2.push(require(`../images/gallery/residential/residential${num}.webp`))
    });

    const galleries = new Map();
    galleries.set("Residential", gal2);
    galleries.set("Commercial", gal1);

    const [selectedGallery, setSelectedGallery] = useState(gal2);

    const selectionHandler = (galleryName) => {
        setSelectedGallery(galleries.get(galleryName));
        console.log(galleryName);
        console.log(selectedGallery);
    }

    return (
        <Container>
            <GalleryOptionsSpeedDial selectionHandler={selectionHandler} />
            <Gallery images={selectedGallery} ></Gallery>
        </Container >
    )
}
